import { __decorate } from "tslib";
import { Component, Prop, Watch, Ref, } from 'vue-property-decorator';
import AddEditOrgBase from './add-edit-org-base';
import { DingtalkIntegrated, WechatIntegrated, } from './addConfig-formItem/index';
import { OrgManageService } from '@/sevices';
import { SourceType, Strategy, } from '../../../typings/organization/org-manage';
import { StaffSelector } from '@cloudpivot-hermes/common-components';
import * as Utils from '@/common/utils';
import { namespace } from 'vuex-class';
const Organization = namespace('Organization');
let OrgDetail = class OrgDetail extends AddEditOrgBase {
    constructor() {
        super(...arguments);
        this.SourceType = SourceType;
        this.isShowOrgListModal = false;
        this.orgSyncLoading = false;
        this.locking = false;
        this.subOrgNames = [];
    }
    get detailMaintainWays() {
        let result = [];
        result = this.maintainWays.filter((item) => {
            return item.value === this.form.sourceType;
        });
        return result;
    }
    // 编辑时，上级组织要排序自身及自身的下级
    get parentOrgParams() {
        return {
            excludeOrgId: this.form.id,
            strategy: Strategy.ExcludeChild,
            resourceCode: Strategy.OrgManageCode,
        };
    }
    onDetailChange(v) {
        this.form = { ...this.form, ...v };
        super.setRules();
    }
    onFormChange() {
        Utils.trim(this.form);
    }
    showConfigRule() {
        super.showConfigRule();
    }
    orgSync() {
        this.orgSyncLoading = true;
        this.locking = true;
        OrgManageService.orgSync({ orgId: this.form.id }).then((res) => {
            if (res.success) {
                this.$message.success('同步正在进行中，由于时间存在延迟，请稍后查看');
            }
        }).finally(async () => {
            this.orgSyncLoading = false;
            await Utils.sleep(10000);
            this.locking = false;
        });
    }
    async submit() {
        const isValid = this.$refs.cForm.validate();
        if (!isValid) {
            return {
                success: false,
            };
        }
        const params = super.paramsFormator();
        params.id = this.curEditOrgDetail.id;
        if (Array.isArray(this.form.parentOrg) && this.form.parentOrg.length) {
            params.parentDepartmentId = this.form.parentOrg[0].id;
        }
        const res = await OrgManageService.updateOrg(params);
        if (res.success) {
            this.$message.success('编辑成功');
            if (this.form.sourceType === SourceType.DingtalkMaintain) {
                OrgManageService.registerCallbackUrl({ orgId: res.data?.id }).catch(() => { });
            }
            return res;
        }
    }
    showSecret(success) {
        this.$confirm({
            title: '该信息属于企业高保密信息，相当于个人银行卡及密码，请勿随意传播，请避免企业信息泄露。',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                success();
            },
        });
    }
    async showEnableOrg() {
        if (this.isEdit)
            return;
        const res = await this.$parent.getSubOrganization(Number(this.form.status));
        if (!res.success)
            return;
        if (res.data?.length) {
            this.subOrgNames = res.data?.map(item => item.name);
            this.isShowOrgListModal = true;
        }
        else {
            this.$confirm({
                title: `${this.form.status ? '停用' : '启用'}组织`,
                content: `确定要${this.form.status ? '停用' : '启用'}该组织吗`,
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.enableOrg({ isEnableChild: false }).then(() => {
                            resolve(null);
                        }).catch((e) => {
                            reject(e);
                        });
                    });
                },
            });
        }
    }
    async enableOrg(data) {
        return OrgManageService.enableOrg({
            id: this.curEditOrgDetail.id,
            status: Number(!this.form.status),
            lowerLevel: data.isEnableChild,
        }).then((res) => {
            if (res.success) {
                const result = true;
                data.cb && data.cb(result);
                this.checkOrg(false);
                this.form.status = !this.form.status;
                this.$message.success(this.form.status ? '组织已启用' : '组织已停用');
            }
        }).catch(() => {
            const result = false;
            data.cb && data.cb(result);
        });
    }
    cancel() {
        this.cForm.clearValidate();
        this.cForm.resetFields();
    }
};
__decorate([
    Ref()
], OrgDetail.prototype, "cForm", void 0);
__decorate([
    Prop()
], OrgDetail.prototype, "curEditOrgDetail", void 0);
__decorate([
    Prop({ default: false })
], OrgDetail.prototype, "isEdit", void 0);
__decorate([
    Prop({ default: false })
], OrgDetail.prototype, "loading", void 0);
__decorate([
    Organization.Action('checkOrg')
], OrgDetail.prototype, "checkOrg", void 0);
__decorate([
    Watch('curEditOrgDetail', { immediate: true, deep: true })
], OrgDetail.prototype, "onDetailChange", null);
__decorate([
    Watch('form', { immediate: true, deep: true })
], OrgDetail.prototype, "onFormChange", null);
OrgDetail = __decorate([
    Component({
        name: 'OrgDetail',
        components: {
            cForm: () => import('@/components/shared/CForm/Form.vue'),
            cFormItem: () => import('@/components/shared/CForm/FormItem.vue'),
            DingtalkIntegrated,
            WechatIntegrated,
            ConfigRule: () => import('./config-rule/index.vue'),
            OrgListModal: () => import('./org-list-modal.vue'),
            StaffSelector,
        },
    })
], OrgDetail);
export default OrgDetail;
