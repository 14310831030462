import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let SyncToDingtalk = class SyncToDingtalk extends Vue {
    showConfigRule() {
        this.$emit('showConfigRule');
    }
};
__decorate([
    Prop()
], SyncToDingtalk.prototype, "dingTalk", void 0);
SyncToDingtalk = __decorate([
    Component({
        name: 'SyncToDingtalk',
        components: {
            cFormItem: () => import('@/components/shared/CForm/FormItem.vue'),
        },
    })
], SyncToDingtalk);
export default SyncToDingtalk;
