import { Vue } from 'vue-property-decorator';
import { SourceType, SourceTypeValueToLabelMap, } from '../../../typings/organization/org-manage';
export default class AddEditOrgBase extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            name: '',
            parentOrg: null,
            status: false,
            userNum: 0,
            deptNum: 0,
            createdTime: '',
            sourceType: SourceType.SelfMaintain,
            dingTalk: {
                corpId: '',
                corpSecret: '',
                agentId: '',
                appkey: '',
                appSecret: '',
                scanAppId: '',
                scanAppSecret: '',
                pcServerUrl: '',
                synRedirectUri: '',
            },
            wechat: {
                corpId: '',
                corpSecret: '',
                agentId: '',
                appSecret: '',
                pcServerUrl: '',
                synRedirectUri: '',
            },
        };
        this.paramsErrMsg = {
            dingTalk: {
                corpId: 'CorpID不能为空',
                corpSecret: 'SSOSecret不能为空',
                agentId: 'AgentID不能为空',
                appkey: 'Appkey不能为空',
                appSecret: 'AppSecret不能为空',
                pcServerUrl: 'PC端首页登录地址不能为空',
                synRedirectUri: '增量回调地址不能为空',
            },
            wechat: {
                corpId: 'CorpId不能为空',
                corpSecret: 'ProviderSecret不能为空',
                agentId: 'AgentID不能为空',
                appSecret: 'AppSecret不能为空',
                pcServerUrl: 'PC端首页登录地址不能为空',
                synRedirectUri: '增量回调地址不能为空',
            },
        };
        this.isShowConfigRule = false;
        // 表单校验
        this.rules = {
            ...this.baseRules,
        };
    }
    get maintainWays() {
        const result = [];
        Object.keys(SourceTypeValueToLabelMap).forEach((key) => {
            result.push({
                label: SourceTypeValueToLabelMap[key],
                value: key,
            });
        });
        return result;
    }
    get isShowSyncToDingtalk() {
        const res = this.form.sourceType === SourceType.DingtalkMaintain;
        return res;
    }
    get isShowSyncToWechat() {
        const res = this.form.sourceType === SourceType.WechatMaintain;
        return res;
    }
    get isShowDingtalkIntegrated() {
        return this.form.sourceType === SourceType.DingtalkMaintain;
    }
    get isShowWechatIntegrated() {
        return this.form.sourceType === SourceType.WechatMaintain;
    }
    get baseRules() {
        const baseRule = {
            name: [
                {
                    required: true,
                    message: '组织名称不能为空',
                    trigger: 'blur',
                },
                {
                    validator: this.nameValidator,
                    message: '请输入中英文、数字及英文符号”()”、“-”、“_”，最大长度为20个字',
                    trigger: 'blur',
                },
            ],
            sourceType: [
                {
                    required: true,
                    message: '组织维护方式不能为空',
                    trigger: 'blur',
                },
            ],
        };
        return baseRule;
    }
    showConfigRule() {
        this.isShowConfigRule = true;
    }
    /**
     * 规则设置入口
     * */
    setRules() {
        this.rules = { ...this.baseRules };
        if (this.form.sourceType === SourceType.DingtalkMaintain) {
            // 钉钉集成
            this.setDingtalkRules();
        }
        if (this.form.sourceType === SourceType.WechatMaintain) {
            // 企业微信集成
            this.setWechatRules();
        }
    }
    /**
     * 设置钉钉参数校验规则
     * */
    setDingtalkRules() {
        this.$set(this.rules, 'dingTalk', {});
        const dingTalkRules = this.paramsErrMsg
            .dingTalk;
        const dingTalkRulesKeys = Object.keys(dingTalkRules);
        dingTalkRulesKeys.forEach((key) => {
            this.$set(this.rules.dingTalk, key, [
                { required: true, message: dingTalkRules[key], trigger: 'blur' },
            ]);
        });
    }
    /**
     * 设置企业微信参数校验规则
     * */
    setWechatRules() {
        this.$set(this.rules, 'wechat', {});
        const wechatRules = this.paramsErrMsg.wechat;
        const wechatRulesKeys = Object.keys(wechatRules);
        wechatRulesKeys.forEach((key) => {
            this.$set(this.rules.wechat, key, [
                { required: true, message: wechatRules[key], trigger: 'blur' },
            ]);
        });
    }
    /**
     * 组织名称校验
     * */
    nameValidator(value) {
        // eslint-disable-next-line
        const reg = /^[\u4e00-\u9fa5_a-zA-Z0-9\-\(\)]{0,20}$/;
        return reg.test(value);
    }
    /**
     * 参数整合
     * */
    paramsFormator() {
        const { form } = this;
        const params = {
            name: form.name,
            sourceType: form.sourceType,
            parentDepartmentId: '',
            sourceConfig: {},
            status: Number(form.status),
        };
        if (form.sourceType === SourceType.DingtalkMaintain) {
            // 钉钉集成
            params.sourceConfig = Object.assign({}, this.form.dingTalk);
        }
        if (form.sourceType === SourceType.WechatMaintain) {
            // 企业微信集成
            params.sourceConfig = Object.assign({}, this.form.wechat);
        }
        return params;
    }
}
