import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import PasswordSpan from '@/components/global/password-span.vue';
let WechatIntegrated = class WechatIntegrated extends Vue {
    showConfigRule() {
        this.$emit('showConfigRule');
    }
    showSecret(success) {
        this.$emit('showSecret', success);
    }
};
__decorate([
    Prop()
], WechatIntegrated.prototype, "wechat", void 0);
__decorate([
    Prop({ default: true })
], WechatIntegrated.prototype, "isEdit", void 0);
WechatIntegrated = __decorate([
    Component({
        name: 'WechatIntegrated',
        components: {
            cFormItem: () => import('@/components/shared/CForm/FormItem.vue'),
            PasswordSpan,
        },
    })
], WechatIntegrated);
export default WechatIntegrated;
