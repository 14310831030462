import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let SyncToWechat = class SyncToWechat extends Vue {
    showConfigRule() {
        this.$emit('showConfigRule');
    }
};
__decorate([
    Prop()
], SyncToWechat.prototype, "wechat", void 0);
SyncToWechat = __decorate([
    Component({
        name: 'SyncToWechat',
        components: {
            cFormItem: () => import('@/components/shared/CForm/FormItem.vue'),
        },
    })
], SyncToWechat);
export default SyncToWechat;
