import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let PasswordSpan = class PasswordSpan extends Vue {
    constructor() {
        super(...arguments);
        this.showPassword = false;
    }
    passwordVisible() {
        if (!this.showPassword && this.fn && typeof this.fn === 'function') {
            this.fn(() => {
                this.showPassword = !this.showPassword;
            });
        }
        else {
            this.showPassword = !this.showPassword;
        }
    }
};
__decorate([
    Prop({
        default: '',
    })
], PasswordSpan.prototype, "value", void 0);
__decorate([
    Prop({
        default: undefined,
    })
], PasswordSpan.prototype, "fn", void 0);
PasswordSpan = __decorate([
    Component({
        name: 'password-span',
        components: {},
    })
], PasswordSpan);
export default PasswordSpan;
